import { IStructureStore, IDsStructureAPI, Structure, IBaseStructureAPI } from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { BaseStructureAPISym } from './symbols'

const dsStructureAPI = (structureStore: IStructureStore, baseStructureAPI: IBaseStructureAPI): IDsStructureAPI => {
	return {
		...baseStructureAPI,
		update: (partial) => {
			structureStore.update(partial)
		},
	}
}

export const DsStructureAPI = withDependencies([Structure, BaseStructureAPISym], dsStructureAPI)
